import "./App.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Suspense, lazy } from "react"
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import carimg from "./assets/car_logo.png"
import { Typography } from "antd";
const AppRouter = lazy(() => import("./routes/index"))
const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<div className="lazy_loading">
          <img src={carimg} alt="logo_laoding" className="logo_laoding" />
          <Typography>Loading...</Typography>
        </div>}>
          <AppRouter />
        </Suspense>
      </PersistGate>
    </Provider>
  )
}

export default App