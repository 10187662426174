import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import CommonReducer from "./toolkitReducer/CommonReducer";

const persistConfig = {
    key: 'root', // The key to use in localStorage
    storage, // Use the storage engine (e.g., localStorage)
    whitelist:['common',]
};


const reducers = combineReducers({
    common :CommonReducer
})
const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
})
const persistor = persistStore(store);

export { store, persistor };