import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    authData: {},
    myData: {},
    dashboardData: {},
    bookedUserData: {},
}

export const authSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        AuthDataStore: (state, action) => {
            state.authData = action.payload
        },
        profileDataStore: (state, action) => {
            state.myData = action.payload
        },
        dashboardDataStore: (state, action) => {
            state.dashboardData = action.payload
        },
        bookedUser: (state, action) => {
            state.bookedUserData = action.payload
        },
    }
})
export const { AuthDataStore, profileDataStore, dashboardDataStore, bookedUser } = authSlice.actions
export default authSlice.reducer